import React, { useState } from 'react';
import { Card, CardHeader, Typography, Button, CardBody, CardFooter, IconButton, Input } from "@material-tailwind/react";

function DaybookTableComponent({ TABLE_HEAD, datakey, daybooks, onApprove }) {//rowsPerPage
    const [currentPage, setCurrentPage] = useState(1);

    // const indexOfLastRow = currentPage * rowsPerPage;
    // const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    // const currentRows = daybooks.slice(indexOfFirstRow, indexOfLastRow);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <Card className="h-full w-full">
            <CardHeader floated={false} shadow={false} className="rounded-none">
                <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
                    <div>
                        <Typography variant="h5" color="blue-gray">
                        </Typography>
                        <Typography color="gray" className="mt-1 font-normal">
                            Transactions For Approval
                        </Typography>
                    </div>
                    <div className="flex w-full shrink-0 gap-2 md:w-max">
                        <div className="w-full md:w-72">
                            <Input label="Search" />
                        </div>
                       
                    </div>
                </div>
            </CardHeader>
            <CardBody className="overflow-scroll px-0">
                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                        <tr>
                            {TABLE_HEAD.map((head, index) => (
                                <th
                                    key={index}
                                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                                >
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal leading-none opacity-70"
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {daybooks.map((daybook) => (
                            <tr className='text-sm' key={daybook.voucher_no}>
                                {datakey.map((key, colIndex) => (
                                    <td key={colIndex} className="px-6 py-0 whitespace-nowrap">
                                        {key === 'approved' ? (daybook.approved ? 'Yes' : 'No') : daybook[key]}
                                    </td>
                                ))}
                                <td className="px-6 py-2 h-6 whitespace-nowrap">
                                    {!daybook.approved && (
                                        <button onClick={() => onApprove(daybook.id)} className="flex items-center gap-3 rounded h-10 text-white p-1 text-xs">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="red" className="size-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                            </svg>

                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </CardBody>
          
        </Card>
    );
}

export default DaybookTableComponent;
