import React, { useContext, useState } from "react";
import {
  IsDeviceConnected,
  CaptureFinger,
  GetConnectedDeviceList,
  InitDevice,
  GetMorFinAuthInfo,
  GetTemplate,
  VerifyFinger,
} from "../pages/goldloan/morfinauth";
import { AppContext } from "../context/appContext";
import axios from "axios";
import { Button } from "@material-tailwind/react";

function ApprovalModal({ isVisible, onClose, onConfirm }) {
  const [userId, setUserId] = useState(""); //to be changed
  const [userName, setUserName] = useState("");
  const { baseurl, ucode, comcode, brcode } = useContext(AppContext);
  let [verify, setVerify] = useState(true);

  const [fin, setFin] = useState("");
  const [fpMessage, setFpMessage] = useState("");
  const [verifyProcess, setVerifyProcess] = useState(false);

  const verifyUser = async () => {
    setFpMessage("");
    setVerify(true);

    setVerifyProcess(true);
    let list = GetConnectedDeviceList();

    if (list.data.ErrorDescription !== "Connected Device :MFS500") {
      onClose();
      resetModal();
      alert("Please connect recommended Fingerprint Scanner");
      return;
    }
    let connectedDvc = list.data.ErrorDescription.split(":");
    connectedDvc = connectedDvc[1].split(",")[0];

    let clientKey = "";
    GetMorFinAuthInfo(connectedDvc, clientKey);

    let result = CaptureFinger(60, 10);

    let template = GetTemplate(0);

    let fp;
    let fp_verify = false; // for checking the user is verified or not

    try {
      const res1 = await axios.get(
        `${baseurl}user/checkid/${userName}/${ucode}/`
      );

      // fingerprint list of user
      fp = await res1.data.data;
      setUserId(res1.data.id);

      if (res1.data.message == "1") {
        setFpMessage("Maker and Approver cannot be same...!");
        setVerifyProcess(false);
        setVerify(true);
      } else {
        setVerify(true);
        setVerifyProcess(false);
        setFpMessage("");
        if (result.httpStaus) {
          setFin(CaptureFinger(60, 10));
        }

        // verifying fingerprint
        for (let i = 0; i < fp.length; i++) {
          // device built-in function to verify fingerprint
          let res = VerifyFinger(template.data.ImgData, fp[i].fp, 0);

          if (res.httpStaus) {
            // breaks the loop when one of the fingerprint matches
            if (res.data.Status) {
              fp_verify = true;

              setVerify(false);
              setVerifyProcess(false);
              setFpMessage("");

              break;
            } else {
              if (res.data.ErrorCode != "0") {
              } else {
                fp_verify = false;

                setVerify(true);
                setVerifyProcess(false);
              }
            }
          } else {
            setVerify(true);
            setVerifyProcess(false);
          }
        }
        if (!fp_verify) {
          setVerify(true);
          setFpMessage("Finger not matched");
          setVerifyProcess(false);
        }
      }
    } catch (error) {
      if (error.response.data.status_code === 404) {
        setFpMessage(error.response.data.errors);
      }
      setVerifyProcess(false);

      return;
    }
  };

  const resetModal = () => {
    setVerifyProcess(false);
    setFin("");
    setFpMessage("");
    setUserId("");
  };
  if (!isVisible) return null;

  const disableFeature = async (key) => {
    setFpMessage("");
    try {
      const response = await axios.get(
        `${baseurl}predbvalue/disable-feature/${comcode}/${brcode}/${key}/`
      );

      if (response.status === 200) {
        if (
          response.data.data &&
          response.data.data[0] &&
          !response.data.data[0].verify
        ) {
          setFpMessage("");
          setUserId(ucode);
          setVerify(false);
        } else {
          alert("You do not have access.");
        }
      }
    } catch (error) {
      if (error.response.status === 404) {
        alert(error.response.data.message);
      }
    }
  };
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="flex sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  Are you sure you want to approve?
                </h3>
              </div>
            </div>
            <div className="w-full my-2 flex justify-center items-center">
              <input
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="User Name"
                style={{ border: "1px solid" }}
              />

              <Button
                size="sm"
                disabled={userName.length > 0 ? false : true}
                className="ms-2"
                loading={verifyProcess}
                onClick={() => verifyUser()}
              >
                {!verifyProcess ? "verify" : "verifying"}
              </Button>
              <Button
                size="sm"
                className="ms-2"
                onClick={() => disableFeature("fp")}
              >
                Disable Fingerprint
              </Button>
            </div>
            <div className="w-full flex justify-center items-center">
              <div className="size-20 flex justify-center items-center border border-black">
                {fin && fin.data.ErrorCode === "0" ? (
                  <img
                    className="size-16"
                    src={`data:image/bmp;base64,${fin.data.BitmapData}`}
                  />
                ) : (
                  <span className="text-sm">fingerprint</span>
                )}
              </div>
            </div>
            <div className="w-full flex justify-center items-center">
              {!verify && <span style={{ color: "green" }}>User Verified</span>}
              {fpMessage && <span style={{ color: "red" }}>{fpMessage}</span>}
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <Button
              disabled={verify}
              onClick={() => {
                onConfirm(userId);
                resetModal();
              }}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                onClose();
                resetModal();
              }}
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApprovalModal;
