import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashBoard from "../../components/DashBoard";

import LoanCloseComponent from "./loanCloseHeader";
import SimpleTable2 from "../../components/table/SimpleTable2";
// import LoanCloseCheckDetails from './loanCloseCheckDetails';
import LoanCloseDivision from "./loanCloseRight";
import LoanCloseAmount from "./loanCloseLeft";
import { LoanCloseContext } from "./LoanCloseContext";
import { AppContext } from "../../context/appContext";
import LoandetailModal from "./LoandetailModal";
import axios from "axios";
import AlertMessage from "../../components/alert/Alert";
import { PlusIcon } from "@heroicons/react/24/outline";

function LoanClose(props) {
  // testing data

  let {
    sajdata,
    total1,
    total,
    finaldc11,
    finalnotice11,
    finalgst11,
    finalotc11,
    finalinterest11,
    finalamt11,
    successMessage1,
    open1,
    setOpen1,
    mode,
    setData,
    runningTotal,
    setRunningTotal,
    handleAmount,
    setSuccessMessage1,
    amnt,
    setAmnt,
    view,
    setView,
  } = useContext(LoanCloseContext);
  const [amountList, setAmountList] = useState([]);
  const column = ["MODE", "AMOUNT", "BANK", "REF", "ACTION"]; // table head
  const datakey = ["mode", "amount", "bank", "refno"]; // amount details key
  const [voucherNo, setVoucherNo] = useState("");
  const { sidebarToggle, baseurl, comcode, ucode, brcode, gcode } =
    useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (view) {
      setAmountList([]); // Clear the amountList when view is true
    }
  }, [view]);
  const goToDetails = (voucher_no) => {
    const data = { voucher_no: voucher_no };
    navigate(`/loan-close-voucher/`, { state: data });
  };

  const {
    register,
    setValue,
    handleSubmit,

    reset,
    formState: { errors },
  } = useForm();
  const [isOpen, setIsOpen] = useState(false);

  // NEW STATE

  const handlePlusIconClick = () => {
    setView(false);
    handleSubmit(() => {
      // Check if the mode already exists in the amountList
      const modeExists = amountList.some((item) => item.mode === amnt.mode);

      if (modeExists) {
        // alert(
        //   `The mode "${amnt.mode}" is already added. Please choose a different mode.`
        // );
        setOpen1(true);
        setSuccessMessage1(
          `The mode "${amnt.mode}" is already added. Please choose a different mode.`
        );
        return; // Stop further execution if mode already exists
      }

      addData();

      // Fetch the current value in the "amnt" input field
      const inputAmount =
        parseFloat(document.getElementsByName("amount")[0].value) || 0;

      // Update the running total
      const updatedTotal = runningTotal + inputAmount;

      // Update state and synchronize with the "amnt" input field
      setRunningTotal(updatedTotal); // Update running total state
      setValue("amnt", updatedTotal); // Set value of the "amnt" input field
      handleAmount(updatedTotal);
      //console.log("Updated Total:", updatedTotal); // Debugging
      setAmnt({ mode: "", amount: "", bank: "", refno: "" });

      reset();
    })();
  };

  const addData = (e) => {
    setAmountList((prev) => [...prev, amnt]);
  };

  const deleteEle = (i) => {
    const ele = amountList && amountList.filter((item, index) => i !== index);
    // setTempCashBal((prev) => prev + parseFloat(formData.amount[i].amount));

    setAmountList(ele);
  };

  const handleModeChange = (e) => {
    setAmnt({ ...amnt, [e.target.name]: e.target.value });
  };

  const loanclose = async (data) => {
    if (total1 > 0) {
      if (total1 === total) {
        const formData1 = new FormData();
        formData1.append("fk_AccountingHead", 3);
        formData1.append("ucode", ucode);
        formData1.append("gcode", gcode);
        formData1.append("comcode", comcode);
        formData1.append("brcode", brcode);
        formData1.append("code", "code");
        formData1.append("voucher_no", 1);
        formData1.append("trdate", "2024-10-08");
        formData1.append("name", sajdata.total_values.name);
        formData1.append("closed_rate", sajdata.total_values.intrest);
        formData1.append("loanid", sajdata.total_values.loanid);
        formData1.append("close", "yes");
        formData1.append("intrest", sajdata.total_values.intrest);
        formData1.append("principle", finalamt11);
        formData1.append("interest", finalinterest11);
        formData1.append("otc", finalotc11);
        formData1.append("gst", finalgst11);
        formData1.append("notice", finalnotice11);
        formData1.append("dc", finaldc11);
        formData1.append("arrayamt", JSON.stringify(amountList));

        try {
          const response = await axios.post(
            `${baseurl}accounting/add-daybook/`,
            formData1
          );

          // Check if the status code is 201 (Created)
          // console.log("iiiiiiiiiiiiiiiiiii",response.key_data);

          if (response.data.key_data === "created") {
            setVoucherNo(response.data.voucher_no);
            alert("Closed Successfully");
            setData("");
            setRunningTotal("");
          } else {
            alert("loan Already closed");
          }
        } catch (error) {
          // Handle errors
          console.error("An error occurred:", error);
          setData("");

          if (error.response) {
            // Server responded with a status code outside the range of 2xx
            alert("error1");
            console.error("Error Response Data:", error.response.data);
          } else if (error.request) {
            // Request was made, but no response received
            alert("error2");
            console.error("Error Request:", error.request);
          } else {
            // Something else happened in setting up the request
            alert("error3");
            console.error("Error Message:", error.message);
          }
        }
      } else {
        alert("Insufficient Amount......!");
      }
    } else {
      alert("Please Enter An Amount More than Zero......!");
    }
  };

  const handleRowClick = () => {
    console.log("fhgh", sajdata);
    if (sajdata === null) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const partclose = async (data) => {
    let temp = amountList.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.amount);
    }, 0);
    console.log(temp, "total");

    if (total1 > 0) {
      if (total1 + 9 < total) {
        if (total1 === parseFloat(runningTotal)) {
          // console.log("ppppppppppppppp");
          const formData1 = new FormData();
          formData1.append("fk_AccountingHead", 3);
          formData1.append("ucode", ucode);
          formData1.append("gcode", gcode);
          formData1.append("comcode", comcode);
          formData1.append("brcode", brcode);
          formData1.append("code", "code");
          formData1.append("voucher_no", 1);
          formData1.append("trdate", "2024-10-08");
          formData1.append("name", sajdata.total_values.name);
          formData1.append("closed_rate", sajdata.total_values.intrest);
          formData1.append("loanid", sajdata.total_values.loanid);
          formData1.append("close", "no");
          formData1.append("intrest", sajdata.total_values.intrest);
          formData1.append("principle", finalamt11);
          formData1.append("interest", finalinterest11);
          formData1.append("otc", finalotc11);
          formData1.append("gst", finalgst11);
          formData1.append("notice", finalnotice11);
          formData1.append("dc", finaldc11);
          formData1.append("arrayamt", JSON.stringify(amountList));

          try {
            const response = await axios.post(
              `${baseurl}accounting/add-daybook/`,

              formData1
            );

            if (response.data.key_data === "created") {
              alert("Payment Successfull");
              setVoucherNo(response.data.voucher_no);
              setData("");
              setRunningTotal("");
            } else {
              alert("loan Already closed");
            }
          } catch (error) {
            // Handle errors
            console.error("An error occurred:", error);

            if (error.response) {
              // Server responded with a status code outside the range of 2xx
              alert("error1");
              console.error("Error Response Data:", error.response.data);
            } else if (error.request) {
              // Request was made, but no response received
              alert("error2");
              console.error("Error Request:", error.request);
            } else {
              // Something else happened in setting up the request
              alert("error3");
              console.error("Error Message:", error.message);
            }
          }
        } else {
          alert("AMOUNT NOT MATCHING......!");
        }
      } else {
        alert("Reduce 10Rs And Pay......!");
      }
    } else {
      alert("Please Enter An Amount More than Zero......!");
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <DashBoard />

      {successMessage1 && open1 && console.log("checq", successMessage1, open1)}
      <div
        className={`${
          !sidebarToggle ? "ms-64 duration-500" : "ms-0 duration-500"
        }`}
      >
        <section className="pt-20 mb-5">
          <h3 className="text-center text-2xl font-bold ">Loan RePay</h3>
          {successMessage1 && open1 && (
            <AlertMessage
              color="red"
              open={open1}
              setOpen={setOpen1}
              message={successMessage1}
            />
          )}
          <LoanCloseComponent />
        </section>

        <section className="ps-5 flex flex-col md:flex-row justify-around">
          <LoanCloseAmount />
          <LoanCloseDivision />
        </section>

        <section className="flex">
          <div className="w-full md:w-1/3 px-2">
            <label className="ml-2">Mode</label>
            <select
              {...register("mode", { required: "Mode is required" })}
              value={amnt.mode}
              onChange={handleModeChange}
              // {...register("mode", { required: true })}
              id="sal"
              name="mode"
              label="Mode"
              className="w-full h-7 border mt-1 text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
              defaultValue="Choose"
              // register={register}
            >
              <option value="" required>
                Select Mode
              </option>
              {mode &&
                mode.map((data, index) => {
                  return (
                    <option key={index} value={data.payment_name}>
                      {data.payment_name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="w-full md:w-1/3 px-2" style={{ maxHeight: "26px" }}>
            <label>Amount</label>
            <input
              {...register("amount", { required: "Amount is required" })}
              type="number"
              name="amount"
              value={amnt.amount || ""}
              onChange={handleModeChange}
              className="form-control no-spinner text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
              required
            />
            {/* {amountError && (
            <span className="text-red-500 text-xs" size="sm">
              {amountError.amount}
            </span> 
          )}*/}
          </div>

          <div className="w-full md:w-1/3 px-2" style={{ maxHeight: "26px" }}>
            <label>Bank</label>
            <input
              {...register("bank", {
                required: amnt.mode !== "Cash" ? "Bank is required" : false,
              })}
              type="text"
              disabled={amnt.mode === "Cash"}
              name="bank"
              value={amnt.bank || ""}
              onChange={handleModeChange}
              className="form-control no-spinner text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
              required
            />
            {/* {amountError && (
            <span className="text-red-500 text-xs" size="sm">
              {amountError.amount}
            </span> 
          )}*/}
          </div>
          <div className="w-full md:w-1/3 px-2" style={{ maxHeight: "26px" }}>
            <label>Acc No</label>
            <input
              {...register("accno", {
                required: amnt.mode !== "Cash" ? "Account is required" : false,
              })}
              disabled={amnt.mode === "Cash"}
              type="text"
              name="accno"
              value={amnt.accno || ""}
              onChange={handleModeChange}
              className="form-control no-spinner text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
              required
            />
            {/* {amountError && (
            <span className="text-red-500 text-xs" size="sm">
              {amountError.amount}
            </span> 
          )}*/}
          </div>
          <div className="w-full md:w-1/3 px-2" style={{ maxHeight: "26px" }}>
            <label>Ref No</label>
            <input
              {...register("refno", {
                required: amnt.mode !== "Cash" ? "refno is required" : false,
              })}
              disabled={amnt.mode === "Cash"}
              type="text"
              name="refno"
              value={amnt.refno || ""}
              onChange={handleModeChange}
              className="form-control no-spinner text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
              required
            />
            {/* {amountError && (
            <span className="text-red-500 text-xs" size="sm">
              {amountError.amount}
            </span> 
          )}*/}
          </div>

          <div className="">
            <PlusIcon
              onClick={handlePlusIconClick}
              className="h-7 w-7 border mt-5 border-lime-500 text-green-500"
            />
          </div>
        </section>

        <SimpleTable2
          columns={column}
          data={amountList && amountList}
          datakey={datakey}
          height="h-44"
          deleteElement={true}
          // setArray={setFormData}
          deleteEle={deleteEle}
        />

        <section className="flex w-full md:flex-row lg:flex-row flex-wrap justify-center m-auto p-5 space-x-5">
          <div className="flex justify-center w-full md:w-auto lg:w-auto">
            <button
              type="button"
              onClick={loanclose}
              className="bg-gray-700 w-full hover:bg-gray-950 text-white py-2 px-4 rounded mt-2"
            >
              Close loan
            </button>
          </div>

          <div className="flex justify-center w-full md:w-auto lg:w-auto">
            <button
              type="button"
              className="bg-gray-700 w-full hover:bg-gray-950 text-white py-2 px-4 rounded mt-2"
              onClick={partclose}
            >
              Part Payment
            </button>
          </div>

          <div className="flex justify-center w-full md:w-auto lg:w-auto">
            <button
              type="button"
              className="bg-gray-700 w-full hover:bg-gray-950 text-white py-2 px-4 rounded mt-2"
              onClick={handleRowClick}
            >
              Detail
            </button>
          </div>

          <div className="flex justify-center w-full md:w-auto lg:w-auto">
            <button
              type="button"
              className="bg-gray-700 w-full hover:bg-gray-950 text-white py-2 px-4 rounded mt-2"
              onClick={() => goToDetails(voucherNo)}
            >
              Print
            </button>
          </div>

          <LoandetailModal isOpen={isOpen} onClose={closeModal} />
        </section>
      </div>
    </div>
  );
}

export default LoanClose;
