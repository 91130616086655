import React, { createContext, useRef, useState } from "react";
import { jwtDecode } from "jwt-decode";
import 'core-js/stable/atob'
export const AppContext = createContext();

function AppProvider({ children }) {
 const baseurl = "https://chemmanurgoldfinance.com/";
// const baseurl = "http://10.54.1.19:8000/";
  const [title, setTitle] = useState("Chemm Finance Ltd");

  const [password_reset, setpassword_reset] = useState(localStorage.getItem("token")
  ? jwtDecode(localStorage.getItem("token")).password_reset
  : null);
  const [comcode, setComcode] = useState(localStorage.getItem("token")
  ? jwtDecode(localStorage.getItem("token")).comcode
  : null); 
  const [brcode, setBrcode] = useState(localStorage.getItem("token")
  ? jwtDecode(localStorage.getItem("token")).brcode
  : null);
  const [ucode, setUcode] = useState(localStorage.getItem("token")
  ? jwtDecode(localStorage.getItem("token")).ucode
  : null);
  const [gcode, setGcode] = useState(localStorage.getItem("token")
  ? jwtDecode(localStorage.getItem("token")).gcode
  : null);
  const [sidebarToggle, setSidebarToggle] = useState(true);

  let [authToken, setAuthToken] = useState(() =>
    localStorage.getItem("token") ? (localStorage.getItem("token")) : null
  );
  
  const [permissions, setPermissions] = useState(localStorage.getItem("token")
  ? jwtDecode(localStorage.getItem("token")).permissions
  : null); 

  const [refreshToken, setRefreshToken] = useState(() =>
    localStorage.getItem("refresh") ? (localStorage.getItem("refresh")) : null)
  let [user, setUser] = useState(() =>
    localStorage.getItem("token")
      ? jwtDecode(localStorage.getItem("token"))
      : null
  );
  const clearToken = () => {
    localStorage.getItem('token') && localStorage.removeItem('token')
    localStorage.getItem('atoken') && localStorage.removeItem('atoken')
    // setComcode('')
  }
  const sideNavRef = useRef(null);

  // sidebar menu
  const [subMenuOpen, setSubMenuOpen] = useState({});

  return (
    <AppContext.Provider
      value={{
        baseurl,
        title,
        comcode,
        setComcode,
        setBrcode,
        setGcode,
        setUcode,
        brcode,
        ucode,
        sidebarToggle,
        setSidebarToggle,
        sideNavRef,
        authToken,
        setAuthToken,
        refreshToken,
        setRefreshToken,
        user,
        setUser,
        gcode,
        subMenuOpen,
        setSubMenuOpen,
        clearToken,
        permissions,
        setPermissions,
        password_reset,
        setpassword_reset,

      }}
    >
      {children}
    </AppContext.Provider>
  );
}
export default AppProvider;
